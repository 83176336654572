import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../components/AuthContext';

const Home: React.FC = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <Container maxWidth="md">
            <Box sx={{ mt: 8, textAlign: 'center' }}>
                <Typography variant="h3" gutterBottom>
                    Welcome to the Hematology-Oncology Reference Tool
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Enhance your knowledge and stay updated with the latest in your field.
                </Typography>
                {!isAuthenticated ? (
                    <Box sx={{ mt: 4 }}>
                        <Button variant="contained" color="primary" component={Link} to="/login" sx={{ mr: 2 }}>
                            Login
                        </Button>
                        <Button variant="outlined" color="primary" component={Link} to="/register">
                            Register
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ mt: 4 }}>
                        <Button variant="contained" color="primary" component={Link} to="/practice" sx={{ mr: 2 }}>
                            MC Question Generator
                        </Button>
                        <Button variant="outlined" color="primary" component={Link} to="/conversations">
                            Get any question answered
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default Home;

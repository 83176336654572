import axios from 'axios';
import { Conversation, ConversationCreate, ConversationUpdate, NewMessage } from '../types';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const api = axios.create({
    baseURL: API_URL,
});

// Add a request interceptor to include the token
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Conversation API methods
export const conversationApi = {
    create: (data: ConversationCreate) =>
        api.post<Conversation>('/conversations', data),

    list: () =>
        api.get<Conversation[]>('/conversations'),

    get: (id: number) =>
        api.get<Conversation>(`/conversations/${id}`),

    update: (id: number, data: ConversationUpdate) =>
        api.patch<Conversation>(`/conversations/${id}`, data),

    delete: (id: number) =>
        api.delete(`/conversations/${id}`),

    addMessage: (conversationId: number, message: NewMessage) =>
        api.post<Conversation>(`/conversations/${conversationId}/messages`, message),
};

export default api;

import React, { useEffect, useState, useRef } from 'react';
import {
    Container,
    Typography,
    Box,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    CircularProgress,
    List,
    ListItem,
    IconButton,
    Drawer,
    Divider,
    useTheme,
    useMediaQuery,
    Collapse,
    Switch,
    FormControlLabel,
    Tabs,
    Tab,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import { conversationApi } from '../services/api';
import {
    Conversation,
    Message,
    ResponseStyle,
    QuestionStyle,
} from '../types';

const drawerWidth = 300;

const Conversations: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
    const [newMessage, setNewMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(!isMobile);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const [newConversationSettings, setNewConversationSettings] = useState({
        responseStyle: 'detailed' as ResponseStyle,
        questionStyle: 'general' as QuestionStyle,
    });

    const [expandedContexts, setExpandedContexts] = useState<Set<number>>(new Set());

    const [editingConversationId, setEditingConversationId] = useState<number | null>(null);
    const [editTitle, setEditTitle] = useState('');

    const [useWebSearch, setUseWebSearch] = useState(true);

    const [contextTabs, setContextTabs] = useState<{ [key: number]: 'textbook' | 'web' }>({});

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        fetchConversations();
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [selectedConversation?.messages]);

    const fetchConversations = async () => {
        try {
            const response = await conversationApi.list();
            setConversations(response.data);
        } catch (error) {
            console.error('Failed to fetch conversations:', error);
        }
    };

    const fetchConversation = async (id: number) => {
        try {
            const response = await conversationApi.get(id);
            setSelectedConversation(response.data);
            setConversations(prev =>
                prev.map(conv => conv.id === id ? response.data : conv)
            );
        } catch (error) {
            console.error('Failed to fetch conversation:', error);
        }
    };

    const handleCreateConversation = async () => {
        try {
            const response = await conversationApi.create({
                response_style: newConversationSettings.responseStyle,
                question_style: newConversationSettings.questionStyle,
            });
            setConversations(prev => [response.data, ...prev]);
            setSelectedConversation(response.data);
            if (isMobile) {
                setDrawerOpen(false);
            }
        } catch (error) {
            console.error('Failed to create conversation:', error);
        }
    };

    const handleDeleteConversation = async (id: number) => {
        if (!window.confirm('Are you sure you want to delete this conversation?')) {
            return;
        }
        try {
            await conversationApi.delete(id);
            setConversations(prev => prev.filter(conv => conv.id !== id));
            if (selectedConversation?.id === id) {
                setSelectedConversation(null);
            }
        } catch (error) {
            console.error('Failed to delete conversation:', error);
        }
    };

    const handleSendMessage = async () => {
        if (!selectedConversation || !newMessage.trim()) return;

        setIsLoading(true);
        try {
            const response = await conversationApi.addMessage(selectedConversation.id, {
                content: newMessage,
                use_web_search: useWebSearch,
            });
            setSelectedConversation(response.data);
            setConversations(prev =>
                prev.map(conv => conv.id === response.data.id ? response.data : conv)
            );
            setNewMessage('');
        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            if (e.shiftKey || e.ctrlKey) {
                // Allow default behavior for Shift+Enter or Ctrl+Enter (new line)
                return;
            }
            // Regular Enter submits the message
            e.preventDefault();
            if (!isLoading && newMessage.trim()) {
                handleSendMessage();
            }
        }
    };

    const toggleContext = (messageId: number) => {
        setExpandedContexts(prev => {
            const newSet = new Set(prev);
            if (newSet.has(messageId)) {
                newSet.delete(messageId);
            } else {
                newSet.add(messageId);
            }
            return newSet;
        });
    };

    const handleStartEdit = (conversation: Conversation) => {
        setEditingConversationId(conversation.id);
        setEditTitle(conversation.title || 'New Conversation');
    };

    const handleCancelEdit = () => {
        setEditingConversationId(null);
        setEditTitle('');
    };

    const handleSaveEdit = async (conversationId: number) => {
        if (!editTitle.trim()) {
            handleCancelEdit();
            return;
        }

        try {
            const response = await conversationApi.update(conversationId, {
                title: editTitle.trim(),
            });
            setConversations(prev =>
                prev.map(conv =>
                    conv.id === conversationId ? { ...conv, title: editTitle.trim() } : conv
                )
            );
            if (selectedConversation?.id === conversationId) {
                setSelectedConversation(prev => prev ? { ...prev, title: editTitle.trim() } : null);
            }
        } catch (error) {
            console.error('Failed to update conversation title:', error);
        }
        handleCancelEdit();
    };

    const handleTabChange = (messageId: number, newValue: 'textbook' | 'web') => {
        setContextTabs(prev => ({
            ...prev,
            [messageId]: newValue
        }));
    };

    const renderMessage = (message: Message) => {
        const hasContext = message.context || message.web_search_context;
        const activeTab = contextTabs[message.id] || (message.context ? 'textbook' : 'web');

        return (
            <Box
                key={message.id}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: message.role === 'user' ? 'flex-end' : 'flex-start',
                    mb: 1.5,
                    width: '100%',
                }}
            >
                <Paper
                    sx={{
                        p: 1.5,
                        maxWidth: '70%',
                        bgcolor: message.role === 'user' ? 'primary.light' : 'grey.100',
                        color: message.role === 'user' ? 'white' : 'text.primary',
                    }}
                >
                    <Box sx={{ fontSize: '0.95rem' }}>
                        <ReactMarkdown>{message.content}</ReactMarkdown>
                    </Box>
                    {hasContext && message.role === 'assistant' && (
                        <>
                            <Button
                                onClick={() => toggleContext(message.id)}
                                startIcon={expandedContexts.has(message.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                size="small"
                                sx={{
                                    mt: 0.5,
                                    color: 'text.secondary',
                                    minHeight: 0,
                                    p: '2px 8px',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    borderTop: '1px solid',
                                    borderColor: 'divider',
                                }}
                            >
                                {expandedContexts.has(message.id) ? 'Hide Sources' : 'Show Sources'}
                            </Button>
                            <Collapse in={expandedContexts.has(message.id)}>
                                <Box sx={{ mt: 1 }}>
                                    <Tabs
                                        value={activeTab}
                                        onChange={(_, newValue) => handleTabChange(message.id, newValue)}
                                        sx={{
                                            minHeight: 36,
                                            '& .MuiTab-root': {
                                                minHeight: 36,
                                                py: 0.5,
                                            },
                                        }}
                                    >
                                        {message.context && (
                                            <Tab
                                                label="Textbook Context"
                                                value="textbook"
                                                sx={{ fontSize: '0.8rem' }}
                                            />
                                        )}
                                        {message.web_search_context && (
                                            <Tab
                                                label="Web Search"
                                                value="web"
                                                sx={{ fontSize: '0.8rem' }}
                                            />
                                        )}
                                    </Tabs>
                                    <Box sx={{
                                        mt: 1.5,
                                        maxHeight: '400px',
                                        overflow: 'auto',
                                        borderTop: 1,
                                        borderColor: 'divider',
                                        pt: 1.5,
                                        '&::-webkit-scrollbar': {
                                            width: '8px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: 'transparent',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                            borderRadius: '4px',
                                        },
                                    }}>
                                        {activeTab === 'textbook' && message.context && (
                                            <Box sx={{ fontSize: '0.875rem' }}>
                                                <ReactMarkdown>{message.context}</ReactMarkdown>
                                            </Box>
                                        )}
                                        {activeTab === 'web' && message.web_search_context && (
                                            <>
                                                <Box sx={{ fontSize: '0.875rem' }}>
                                                    <ReactMarkdown>{message.web_search_context}</ReactMarkdown>
                                                </Box>
                                                {message.citations && (
                                                    <Box sx={{
                                                        mt: 2,
                                                        pt: 1.5,
                                                        borderTop: 1,
                                                        borderColor: 'divider',
                                                    }}>
                                                        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                                                            Citations:
                                                        </Typography>
                                                        <Box sx={{
                                                            fontSize: '0.875rem',
                                                            color: 'text.secondary',
                                                            '& p': {
                                                                m: 0,
                                                                lineHeight: 1.4,
                                                            }
                                                        }}>
                                                            <ReactMarkdown>{message.citations.split('\n').map((citation, index) =>
                                                                `[${index + 1}] ${citation}`
                                                            ).join('\n\n')}</ReactMarkdown>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            </Collapse>
                        </>
                    )}
                </Paper>
            </Box>
        );
    };

    const renderConversationTitle = (conversation: Conversation) => {
        if (editingConversationId === conversation.id) {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                    <TextField
                        size="small"
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                        autoFocus
                        fullWidth
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSaveEdit(conversation.id);
                            } else if (e.key === 'Escape') {
                                handleCancelEdit();
                            }
                        }}
                        sx={{
                            '& .MuiInputBase-input': {
                                py: 0.5,
                                px: 1,
                                fontSize: '0.875rem',
                            }
                        }}
                    />
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                        <IconButton size="small" onClick={() => handleSaveEdit(conversation.id)}>
                            <CheckIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="small" onClick={handleCancelEdit}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            );
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                <Typography noWrap sx={{ flexGrow: 1 }}>
                    {conversation.title || 'New Conversation'}
                </Typography>
                <Box sx={{ display: 'flex', gap: 0.5, ml: 1 }}>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleStartEdit(conversation);
                        }}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteConversation(conversation.id);
                        }}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
        );
    };

    const conversationsList = (
        <Box sx={{
            width: drawerWidth,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexShrink: 0,
            }}>
                <Typography variant="h6">Conversations</Typography>
                <Button
                    startIcon={<AddIcon />}
                    onClick={handleCreateConversation}
                    variant="contained"
                    size="small"
                >
                    New Chat
                </Button>
            </Box>
            <Divider />
            <Box sx={{
                p: 2,
                flexShrink: 0,
            }}>
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                    <InputLabel>Response Style</InputLabel>
                    <Select
                        value={newConversationSettings.responseStyle}
                        label="Response Style"
                        onChange={(e) => setNewConversationSettings(prev => ({
                            ...prev,
                            responseStyle: e.target.value as ResponseStyle
                        }))}
                    >
                        <MenuItem value="detailed">Detailed</MenuItem>
                        <MenuItem value="compact">Compact</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                    <InputLabel>Question Style</InputLabel>
                    <Select
                        value={newConversationSettings.questionStyle}
                        label="Question Style"
                        onChange={(e) => setNewConversationSettings(prev => ({
                            ...prev,
                            questionStyle: e.target.value as QuestionStyle
                        }))}
                    >
                        <MenuItem value="general">General</MenuItem>
                        <MenuItem value="multiple_choice">Multiple Choice</MenuItem>
                        <MenuItem value="short_answer">Short Answer</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Divider />
            <List sx={{
                flexGrow: 1,
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '4px',
                },
            }}>
                {conversations.map((conversation) => (
                    <ListItem
                        key={conversation.id}
                        sx={{
                            cursor: 'pointer',
                            bgcolor: selectedConversation?.id === conversation.id ? 'action.selected' : 'inherit',
                            '&:hover': { bgcolor: 'action.hover' },
                            py: 1,
                        }}
                    >
                        <Box
                            onClick={() => {
                                if (editingConversationId !== conversation.id) {
                                    fetchConversation(conversation.id);
                                    if (isMobile) setDrawerOpen(false);
                                }
                            }}
                            sx={{
                                flexGrow: 1,
                                overflow: 'hidden',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 0.5,
                            }}
                        >
                            {renderConversationTitle(conversation)}
                            <Typography variant="caption" color="text.secondary" noWrap>
                                {new Date(conversation.created_at).toLocaleString(undefined, {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </Typography>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{
            display: 'flex',
            minHeight: 'calc(100vh - 64px)',
            position: 'fixed',
            top: '64px',
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: '#f5f5f5',
        }}>
            {isMobile ? (
                <Drawer
                    variant="temporary"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            top: '64px',
                            height: 'calc(100% - 64px)',
                        },
                    }}
                >
                    {conversationsList}
                </Drawer>
            ) : (
                <Box sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    borderRight: 1,
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    overflow: 'hidden',
                }}>
                    {conversationsList}
                </Box>
            )}

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {isMobile && (
                    <IconButton
                        sx={{ mb: 2, alignSelf: 'flex-start' }}
                        onClick={() => setDrawerOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                )}

                {selectedConversation ? (
                    <Paper
                        elevation={2}
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            bgcolor: 'white',
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                overflow: 'auto',
                                p: 3,
                            }}
                        >
                            {selectedConversation.messages.map(renderMessage)}
                            <div ref={messagesEndRef} />
                        </Box>
                        <Divider />
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                p: 2,
                                bgcolor: 'background.paper',
                            }}
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSendMessage();
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        checked={useWebSearch}
                                        onChange={(e) => setUseWebSearch(e.target.checked)}
                                        disabled={isLoading}
                                    />
                                }
                                label={
                                    <Typography variant="body2" color="text.secondary">
                                        Augment with web search
                                    </Typography>
                                }
                                sx={{ alignSelf: 'flex-start' }}
                            />
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Type your message... (Enter to send, Shift+Enter or Ctrl+Enter for new line)"
                                    disabled={isLoading}
                                    sx={{ flexGrow: 1 }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={handleSendMessage}
                                    disabled={isLoading || !newMessage.trim()}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : 'Send'}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexGrow: 1,
                        }}
                    >
                        <Typography variant="h6" color="text.secondary" gutterBottom>
                            No conversation selected
                        </Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleCreateConversation}
                        >
                            Start a new conversation
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Conversations; 
import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';
import { ButtonProps } from '@mui/material/Button';

// Custom styled AppBar
const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: '#2C3E50', // Deep blue-gray, professional medical color
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

// Create an interface for the NavButton props
interface NavButtonProps extends ButtonProps {
    to?: string;
    component?: React.ComponentType<any>;
}

// Custom styled Button with proper typing
const NavButton = styled(Button)<NavButtonProps>(() => ({
    color: '#FFFFFF',
    margin: '0 4px',
    padding: '6px 16px',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    textTransform: 'none',
    fontWeight: 500,
}));

const Header: React.FC = () => {
    const navigate = useNavigate();
    const { user, isAuthenticated, logout } = useContext(AuthContext);

    const handleLogout = async () => {
        await logout();
        navigate('/login');
    };

    return (
        <StyledAppBar position="static">
            <Toolbar>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        flexGrow: 1,
                        color: '#FFFFFF',
                        fontWeight: 500,
                        letterSpacing: '0.5px'
                    }}
                >
                    Hematology-Oncology Reference Tool
                </Typography>
                <NavButton component={RouterLink} to="/">
                    Home
                </NavButton>
                {isAuthenticated ? (
                    <>
                        <NavButton component={RouterLink} to="/dashboard">
                            Dashboard
                        </NavButton>
                        <NavButton component={RouterLink} to="/practice">
                            MC Question Generator
                        </NavButton>
                        <NavButton component={RouterLink} to="/conversations">
                            Answer My Questions
                        </NavButton>
                        {user?.role === 'admin' && (
                            <NavButton component={RouterLink} to="/admin">
                                Admin
                            </NavButton>
                        )}
                        <NavButton component={RouterLink} to="/profile">
                            Profile
                        </NavButton>
                        <NavButton
                            onClick={handleLogout}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                }
                            }}
                        >
                            Logout
                        </NavButton>
                    </>
                ) : (
                    <>
                        <NavButton component={RouterLink} to="/login">
                            Login
                        </NavButton>
                        <NavButton
                            component={RouterLink}
                            to="/register"
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                }
                            }}
                        >
                            Register
                        </NavButton>
                    </>
                )}
            </Toolbar>
        </StyledAppBar>
    );
};

export default Header;
